import React, { useRef } from 'react'
import ContactUsMain from './contactuscomponent/ContactUsMain';
import ContinentsCityTitle from './contactuscomponent/ContinentsCityTitle';
import ContactUsCitylist from './contactuscomponent/ContactUsCitylist';
import { Helmet } from 'react-helmet';

export default function ContactUs() {
    const ServicesRef = useRef(null);
  
    const gotoServices = () =>
      window.scrollTo({
        top: ServicesRef.current.offsetTop,
        behavior: "smooth",
        // You can also assign value "auto"
        // to the behavior parameter.
      });
  return (
    <>
    <Helmet>
    <title>Best Creative Agency Delhi, Brand Advertising Agency Delhi | HS Ad India | Contact Us</title>
    <meta name="description"content="HS Ad best Advertising, Creative agency in Delhi India provides integrated marketing services to build a brand with Digital Media, ATL / BTL and PR mediums around the Globe."/>
    <meta name="keywords" content="" />
    </Helmet>
      {/* <div className="section aboutme" data-anchor="aboutme">
	    <div className="banner-con ">
      <h1 style={{color:"white"}} className="animated fadeInDown">Connect With US</h1>
      <p className="animated fadeInDown">Contact us</p>
    </div>
    <i className="fa fa-chevron-down fa-3x bounce" onClick={gotoServices}></i>

	  <img src="https://hsadindia.co.in/dev/assets/images/banner-contact-us.jpg" className='img-d' alt=""/>
    <img src="https://hsadindia.co.in/dev/assets/images/banner-contact-us-m.jpg" className='img-m' alt=""/>
     </div> */}
{/* <div className='clearfix' ref={ServicesRef}></div> */}
    <ContactUsMain></ContactUsMain>

     {/* <section className="loction-sec">
  <div className="container">
 <ContinentsCityTitle></ContinentsCityTitle> 

 </div>
 <div className="container">

<ContactUsCitylist></ContactUsCitylist>

 </div>


</section> */}
    </>
  )
}
