import React from "react";
//import { Link } from "react-router-dom";
import OurAmazingTeamTitle from "./OurAmazingTeamTitle";
import HomeTeam from "./HomeTeam";
export default function OurteamSec() {
  return (
    <>
      <div className="team-sec" data-anchor="contact">
        <div className="container">
          <OurAmazingTeamTitle />
        </div>

        <div className="container slider-container">

   <div className="row justify-content-center">
    <div className="col-md-10"><HomeTeam></HomeTeam></div>
  
    </div>
				 {/* <div className="item">
					 <div className="team-b">
					    <div className="img-box">
						   <div className="avtar"><img src="assets/images/team/Hyung-June-Kim.jpg" alt=""/></div>
						 </div>
						<div className="content-box">
							<div className="d-box">
							<h2 className="name">Hyung June Kim</h2>
						    <div className="designnation">Managing Director</div>
							</div>
							<button data-bs-target="#Hyung-June-Kim" data-bs-toggle="modal" className="link-tab"> <i className="icon icon-right-arrow"></i></button>
						 </div>
					 </div>
				 </div>
		   <div className="item">
					 <div className="team-b">
					    <div className="img-box">
						   <div className="avtar"><img src="assets/images/team/Se-Myeong-Jang.jpg" alt=""/></div>
						 </div>
						<div className="content-box">
							<div className="d-box">
							<h2 className="name">Se Myeong Jang</h2>
						    <div className="designnation">Director</div>
							</div>
							<button data-bs-target="#Se-Myeong-Jang" data-bs-toggle="modal" className="link-tab"><i className="icon icon-right-arrow"></i></button>
						 </div>
					 </div>
				 </div>
		   <div className="item ">
					 <div className="team-b">
					    <div className="img-box">
						   <div className="avtar"><img src="assets/images/team/Euijoong-Choi.jpg" alt=""/></div>
						 </div>
						<div className="content-box">
							<div className="d-box">
							<h2 className="name">Euijoong Choi</h2>
						    <div className="designnation">Client Service Director</div>
							</div>
							<button data-bs-target="#Euijoong-Choi" data-bs-toggle="modal" className="link-tab"><i className="icon icon-right-arrow"></i></button>
						 </div>
					 </div>
				 </div>
		   <div className="item" >
					 <div className="team-b">
					    <div className="img-box">
						   <div className="avtar"><img src="assets/images/team/Rakesh-Kumar.jpg" alt=""/></div>
						 </div>
						<div className="content-box">
							<div className="d-box">
							<h2 className="name">Rakesh Kumar</h2>
						    <div className="designnation">Head - Creative (ATL-CR)</div>
							</div>
							<button data-bs-target="#Rakesh-Kumar" data-bs-toggle="modal" className="link-tab"><i className="icon icon-right-arrow"></i></button>
						 </div>
					 </div>
				 </div>
		   <div className="item">
					 <div className="team-b">
					    <div className="img-box">
						   <div className="avtar"><img src="assets/images/team/Awadhesh-Singh.jpg" alt=""/></div>
						 </div>
						<div className="content-box">
							<div className="d-box">
							<h2 className="name">Awadhesh Singh</h2>
						    <div className="designnation">Business Head-Digital & Analytics</div>
							</div>
							<button data-bs-target="#Awadhesh-Singh" data-bs-toggle="modal" className="link-tab"><i className="icon icon-right-arrow"></i></button>
						 </div>
					 </div>
				 </div> */}
				  

		
	  
	  </div>	  
      </div>

      {/* <div
        className="modal fade propop"
        id="Hyung-June-Kim"
        aria-hidden="true"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn close-box"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="icon icon-close"></i>
            </button>
            <div className="modal-body">
              <div className="profile-cont animated fadeInUp">
                <div className="pro-image-box">
                  <img
                    src="assets/images/team/Hyung-June-Kim.jpg"
                    alt="Hyung June Kim"
                  />
                </div>
                <div className="pro-contant">
                  <h3>Hyung June Kim</h3>
                  <h5>Managing Director</h5>
                  <p>
                    At HS Ad, we're dedicated to building a competitive yet
                    friendly work culture in which our employees feel rewarded
                    and provide them with equal opportunity to demonstrate their
                    skills & abilities. We also emphasize on professionalism,
                    responsibility, and accountability. Being a multi-national
                    company, we nurture a work environment that respects and
                    builds on the many strengths of the diversity of our people.
                    To increase understanding across cultures, we routinely have
                    global exchange programs engaging talents between India and
                    other countries and regions.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade propop"
        id="Se-Myeong-Jang"
        aria-hidden="true"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn close-box"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="icon icon-close"></i>
            </button>
            <div className="modal-body">
              <div className="profile-cont animated fadeInUp">
                <div className="pro-image-box">
                  <img
                    src="assets/images/team/Se-Myeong-Jang.jpg"
                    alt="Se Myeong Jang"
                  />
                </div>
                <div className="pro-contant">
                  <h3>Se Myeong Jang</h3>
                  <h5>Director</h5>
                  <p>
                    At HS Ad, we're dedicated to building a competitive yet
                    friendly work culture in which our employees feel rewarded
                    and provide them with equal opportunity to demonstrate their
                    skills & abilities. We also emphasize on professionalism,
                    responsibility, and accountability. Being a multi-national
                    company, we nurture a work environment that respects and
                    builds on the many strengths of the diversity of our people.
                    To increase understanding across cultures, we routinely have
                    global exchange programs engaging talents between India and
                    other countries and regions.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade propop"
        id="Euijoong-Choi"
        aria-hidden="true"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn close-box"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="icon icon-close"></i>
            </button>
            <div className="modal-body">
              <div className="profile-cont animated fadeInUp">
                <div className="pro-image-box">
                  <img
                    src="assets/images/team/Euijoong-Choi.jpg"
                    alt="Euijoong  Choi"
                  />
                </div>
                <div className="pro-contant">
                  <h3>Euijoong Choi</h3>
                  <h5>Client Service Director</h5>
                  <p>
                    At HS Ad, we're dedicated to building a competitive yet
                    friendly work culture in which our employees feel rewarded
                    and provide them with equal opportunity to demonstrate their
                    skills & abilities. We also emphasize on professionalism,
                    responsibility, and accountability. Being a multi-national
                    company, we nurture a work environment that respects and
                    builds on the many strengths of the diversity of our people.
                    To increase understanding across cultures, we routinely have
                    global exchange programs engaging talents between India and
                    other countries and regions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade propop"
        id="Rakesh-Kumar"
        aria-hidden="true"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn close-box"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="icon icon-close"></i>
            </button>
            <div className="modal-body">
              <div className="profile-cont animated fadeInUp">
                <div className="pro-image-box">
                  <img
                    src="assets/images/team/Rakesh-Kumar.jpg"
                    alt="Rakesh Kumar"
                  />
                </div>
                <div className="pro-contant">
                  <h3>Rakesh Kumar</h3>
                  <h5>Head - Creative (ATL-CR)</h5>
                  <p>
                    A writer by heart and a copywriter by profession, Rakesh,
                    fondly called Raka by his peers, has created memorable
                    campaigns for some of the finest national and international
                    brands.
                  </p>
                  <p>
                    In a career spanning over fifteen years, Rakesh has worked
                    on categories as diverse as Automobiles, Consumer Durables,
                    Consumer Electronics, Real Estate and Communications. Some
                    of the brands Rakesh has been very closely associated with
                    are Maruti Suzuki, Sony, LG, Yamaha, Reliance
                    Communications, magicbricks, Videocon, Emaar MGF, Sukam,
                    Hindware and The Times Group to name a few.
                  </p>
                  <p>
                    Before being associated with HS Ad India, Rakesh was a
                    creative powerhouse at agencies like Arms Advertising,
                    Hakuhodo Percept Pvt. Ltd., FHO Communications and RK Swamy
                    BBDO Pvt. Ltd.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade propop"
        id="Awadhesh-Singh"
        aria-hidden="true"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn close-box"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="icon icon-close"></i>
            </button>
            <div className="modal-body">
              <div className="profile-cont animated fadeInUp">
                <div className="pro-image-box">
                  <img
                    src="assets/images/team/Awadhesh-Singh.jpg"
                    alt="Awadhesh Singh"
                  />
                </div>
                <div className="pro-contant">
                  <h3>Awadhesh Singh</h3>
                  <h5>Business Head-Digital & Analytics</h5>
                  <p>
                    With over 15 years of experience, Awadhesh Singh is a
                    seasoned professional serving HSAD India as the Business
                    Head for Digital & Analytics, spearheading a diverse team
                    with unwavering expertise. He holds an MBA from the
                    prestigious Indian Institution of Foreign Trade. Awadhesh's
                    extensive work experience is highlighted by his notable
                    contributions to renowned accounts such as Microsoft,
                    Oracle, Nestle, Aviva, and Reckitt Benckiser, among many
                    others.
                  </p>
                  <p>
                    Driven by a vision for organisational growth, both
                    personally and professionally, Awadhesh embodies a
                    sophisticated and forward-thinking approach to leadership.
                    His keen eye for innovation and deep understanding of the
                    digital landscape have consistently propelled his teams to
                    achieve exceptional results. With a profound commitment to
                    the development and success of his company and colleagues,
                    he fosters an environment conducive to continuous learning
                    and advancement.
                  </p>
                  <p>
                    He inspires his team to navigate the ever-evolving digital
                    and analytics landscape, driving success through strategic
                    insights and cutting-edge solutions. With his blend of
                    expertise, vision, and a genuine passion for growth,
                    Awadhesh brings an unparalleled level of excellence to his
                    role, ensuring both individual and collective triumph.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade propop"
        id="Dhirender-Singh"
        aria-hidden="true"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn close-box"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="icon icon-close"></i>
            </button>
            <div className="modal-body">
              <div className="profile-cont animated fadeInUp">
                <div className="pro-image-box">
                  <img
                    src="assets/images/team/Dhirender-Singh.jpg"
                    alt="Awadhesh Singh"
                  />
                </div>
                <div className="pro-contant">
                  <h3>Dhirender Singh</h3>
                  <h5>General Manager, BTL</h5>
                  <p>
                    Dhirendra brings with him over 15 years of experience in the
                    area of Brand Visibility, Retail Store Planning and
                    Roll-out. He has had a successful stint with Cheil Worldwide
                    (Samsung), Apple India, and has the experience of working in
                    Dubai and the UK. He is an MBA from the UK with a Bachelor's
                    Degree in Architecture and a Master's Degree in Planning
                    from SPA, New Delhi. He is also an alumni of the famous The
                    Doon School. He loves sports and can be found playing hockey
                    every Sunday morning at the Major Dhyan Chand National
                    Stadium.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
