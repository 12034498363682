import React, { useState, useEffect,useRef } from "react";
import { slice } from "lodash";
import { Helmet } from "react-helmet";


export default function PrivacyPolicy() {
    // const ServicesRef = useRef(null);

    // const gotoServices = () =>
    //   window.scrollTo({
    //     top: ServicesRef.current.offsetTop,
    //     behavior: "smooth",
    //     // You can also assign value "auto"
    //     // to the behavior parameter.
    //   });
      const [apilink] = useState('https://hsadindia.co.in/admin/api-add-privacy-policy-website') 
      const [post, setPost] = useState([])
      // const [setIsCompleted] = useState(false)
      const [index] = useState()
      let initialPosts = slice(post, 0, index)
      const getData = (apilink) => {
        fetch(apilink)
          .then((res) => res.json())
          .then((json) => setPost(json.data))
          .catch((e) => console.log(e))
      }
    
    
    
      useEffect(() => {
        getData(apilink);
      }, [apilink])

  return (
    <>
       <Helmet>
       <title>Best Creative Agency Delhi, Brand Advertising Agency Delhi | HSAD India | PrivacyPolicy</title>
    <meta name="description"content="HS Ad best Advertising, Creative agency in Delhi India provides integrated marketing services to build a brand with Digital Media, ATL / BTL and PR mediums around the Globe."/>
    <meta name="keywords" content="" /> 
       </Helmet>
      {/* <div className="section aboutme" data-anchor="aboutme">
	    <div className="banner-con ">
      <h1 style={{color:"white"}} className="animated fadeInDown">HSAD India</h1>
      <p className="animated fadeInDown">Privacy Policy</p>
   
    </div>
    <i className="fa fa-chevron-down fa-3x bounce" onClick={gotoServices}></i>

	  <img src="assets/images/banner-blog-d.jpg"   className='img-d' alt=""/>
      <img src="assets/images/banner-team-m.jpg"   className='img-m' alt=""/>
     </div> */}
     <section className="blog-sec" >
     <div className="container">
         <div className="title-h mb-5">
         
                  <h2 >HSAD India <span>Privacy Policy</span></h2>
                  {/* <p dangerouslySetInnerHTML={{ __html: itemm.why_choose_us }}></p> */}
          
        </div>

         </div>
             <div className='container'>
              <div className='privacypolicy'>
              {
      initialPosts.map((item, i) => {
                    return (
                       
                         <React.Fragment key={item.id}>
                        {/* <h2 dangerouslySetInnerHTML={{ __html: item.title }}></h2> */}
                        <div className="condet"  dangerouslySetInnerHTML={{ __html: item.description }}></div>
                        </React.Fragment>
                       
                 );
                 })} 

              </div>


             </div>


     </section>
    </>
  )
}
