import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
export default function HomeTabContant() {

  
      return (
        <div >
          <div className="tab-content-title">
        <div className="main-title-n">
             <div className="tab-title"><h2>What <br/><span>We Do</span></h2></div>

        </div>

          <div className="title-contant-n">We believe the path to world-class work is through rigorous creative strategy that adds value to people's lives. Our approach tends to bring brand purpose upstream into the brand's preposition & downstream into its communication. </div>       
          </div>
        
        <div className="gallery-b"> 



        <OwlCarousel className='owl-theme'  margin={10} items={1} nav >
        <div className="item">
          <div className="main-display">
          <div className="colm-b">
            <div className="row-b">
              <div className="big-img">
                <a href="https://www.youtube.com/watch?v=fXL4vSS2THE" data-caption="You’ve made your mark. Now make a statement and show the world you are truly a class apart.  Celebrate your success and every moment of it with the #LGOLED #TV.<br/> Explore the widest range, from the world's smallest to biggest OLED TVs, and experience cinema, sports and gaming like never before. "  data-fancybox="video-gallery-Creative" >
                  <img src="assets/images/homepage-img/creative/Style-meets-art.jpg" alt=""/>
                 <div className="img-content">
                 <h3>The One and Only</h3>
                 <p>LG OLED TV with the King of Bollywood</p>
                 </div>
              </a></div>
              <div className="small-img">
              <img src="assets/images/homepage-img/creative/StunninglyStylish.jpg" alt=""/>
                <a href="https://www.youtube.com/watch?v=qwHaH-gxe1c" data-caption="Elevate your kitchen aesthetic with the LG Stunningly Stylish Refrigerator - a seamless blend of beauty and innovation. Its sleek design redefines freshness in style."  data-fancybox="video-gallery-Creative" > 
                <div className="img-content">
                 <h3>Stunningly Stylish</h3>
                 <p>Perfect Refrigerator For Modern Homes</p>
                 </div>
                 
                 
                 </a></div>
            </div>
            <div className="row-b">
            <div className="small-img">
            <a href="https://www.youtube.com/watch?v=eFW6iqF1siE" data-caption="Now, cook yummy food the quick and easy way with  <strong>#ScanToCook</strong> feature of the <strong>#LGMicrowaveOven</strong>.<br/> Simply scan the barcode on the pack with the LG ThinQ App and send cooking directions to the microwave and enjoy your favorite meals with your loved ones in no time."  data-fancybox="video-gallery-Creative" >
              <img src="assets/images/homepage-img/creative/Scan-to-Cook.jpg" alt=""/>
            <div className="img-content">
                 <h3>Scan To Cook</h3>
                 <p>Ease-Of-Cooking with LG ThinQ</p>
                 </div></a>
            </div>
            <div className="big-img">
            <a href="https://www.youtube.com/watch?v=FFHhyo6qnww" data-caption=" Kya Hai Aapki Success Ka Taste?<br/>
Royal Green Packaged drinking water takes immense pride to announce its brand ambassador, the enigmatic @SonuSood ;<br/> the one who has been the light for many during hard times. 
"  data-fancybox="video-gallery-Creative" >
              <img src="assets/images/homepage-img/creative/Royal-Green.jpg" alt=""/>
            <div className="img-content">
                 <h3>Royal Green</h3>
                 <p>Taste of Success for Sonu Sood</p>
                 </div>
                 </a>
            </div>
             

            </div>
          </div>
          <div className="colm-s">
             <div className="row-box">
              <div className="smallimg-dis">
              <a href="https://www.youtube.com/watch?v=GqZV3nUZSJE" data-caption="This Festive Season, experience the power of Self Lit Pixels! 
Meet the all new LG OLED with over 100 million of SELF-LIT Pixels.<br/> When every pixel lights by itself, what you see becomes more expressive, realistic, responsive, and artistic."  data-fancybox="video-gallery-Creative" >
              <img src="assets/images/homepage-img/creative/Think-OLED-Think-LG.jpg" alt=""/>
              <div className="img-content">
                 <h3>Think OLED Think LG</h3>
                 <p>The Power of Self Lit Pixels</p>
                 </div>
                 </a>
              </div>

             </div>
             <div className="row-box">
              <div className="smallimg-dis">
              <a href="https://www.youtube.com/watch?v=beEhH2mXNB0" data-caption="Experience a touch of elegance with LG InstaView Refrigerators!<br/> Discover how LG InstaView Refrigerator fits perfectly into your modern lifestyle. "  data-fancybox="video-gallery-Creative" >
              <img src="assets/images/homepage-img/creative/Stylemeetsart-1.jpg" alt=""/>
              <div className="img-content">
                 <h3> Style Meets Art </h3>
                 <p>Embrace style and functionality with LG InstaView Refrigerators.</p>
                 </div></a>
              
              </div>

             </div>
          </div>

          </div>
        </div>


          <div className="item">
          <div className="main-display">
          <div className="colm-b">
            <div className="row-b">
              <div className="big-img"><a href="https://www.youtube.com/watch?v=H6rRDcpI0y4" data-caption="LG introduces India's First AC with Energy Manager. Simply pre-set energy usage limit in the app, AC automatically optimizes consumption and helps you save big on electricity bills.<br/>No more electricity bill shocks. You Decide Your Bill"  data-fancybox="video-gallery-Creative" ><img src="assets/images/homepage-img/creative/LG-Energy-Manager.jpg" alt=""/>
                 <div className="img-content">
                 <h3>LG Energy Manager</h3>
                 <p>Save Big on Electricity Bills</p>
                 </div>
              </a></div>
              <div className="small-img"><a href="https://www.youtube.com/watch?v=a11VRDqs_Bc" data-caption="LG India, known for 'innovation for a better life' on Global Recycling Day, introduced an Innovative Reusable Tool for Education made out of empty LG packaging cartons.<br/> The idea is to enable children who still write on floors and don't even have enough sustainable tools to write.<br/> LG BOX SLATE is a powerful idea that empowers every child with the Right to Write. "  data-fancybox="video-gallery-Creative" > 
              <img src="assets/images/homepage-img/creative/LG-Box-Slate.jpg" alt=""/>
              <div className="img-content">
                 <h3>LG Box Slate</h3>
                 <p>Every Child has Right to Write</p>
                 </div>
                 </a></div>
            </div>
            <div className="row-b">
            <div className="small-img"><a href="https://www.youtube.com/watch?v=iaNks3A8n3Y" data-caption="With LG Wi-Fi Convertible Side by Side Refrigerator, there's always room for extra love and sweet surprises!<br/> Enjoy the convenience of converting your freezer to fridge from Anywhere with your phone using LG ThinQ App."  data-fancybox="video-gallery-Creative" >
              <img src="assets/images/homepage-img/creative/LG-Wi-Fi-Convertible.jpg" alt=""/>
            <div className="img-content">
                 <h3>LG Wi-Fi Convertible</h3>
                 <p>There is Always Room for More</p>
                 </div></a></div>
            <div className="big-img"> <a href="https://www.youtube.com/watch?v=0qRWhBfq8xc" data-caption="LG celebrating 20 years anniversary in India with 'Celebrating Togetherness'!<br/> Like a true companion, being with you in your good and tough times makes the journey, all the more special."  data-fancybox="video-gallery-Creative" >
              <img src="assets/images/homepage-img/creative/LG-Astronaut.jpg" alt=""/>
            <div className="img-content">
                 <h3>LG Astronaut</h3>
                 <p>20 Years Anniversary Celebration</p>
                 </div>
                 </a></div>
             

            </div>
          </div>
          <div className="colm-s">
             <div className="row-box">
              <div className="smallimg-dis">
              <a href="https://www.youtube.com/watch?v=dZVpvhQtiRQ" data-caption="INDIA KA BATTLEGROUNDS are ready and waiting for you with exciting rewards!<br/> Here's an exciting gateway for the game lovers as we unleash pure thrill in the much-awaited contest! Come, register and Enjoy in your dream battleground."  data-fancybox="video-gallery-Creative" >
              <img src="assets/images/homepage-img/creative/India-Ka-Battlegrounds.jpg" alt=""/>
              <div className="img-content">
                 <h3>Battlegrounds India</h3>
              
                 </div></a>
              </div>

             </div>
             <div className="row-box">
              <div className="smallimg-dis">
              <a href="https://www.youtube.com/watch?v=FmRnR0FeO58" data-caption="Different fabrics? No problem! LG Washing Machine with AI Direct Drive does your laundry intelligently by automatically detecting fabric softness and weight & selecting the optimal wash pattern basis pre-loaded 20,000 wash patterns.<br/> So have fun and let the washing begin with LG Washing Machine!"  data-fancybox="video-gallery-Creative" >
              <img src="assets/images/homepage-img/creative/Go-Easy-Intelligent.jpg" alt=""/>
              <div className="img-content">
                 <h3>Go Easy & Intelligent </h3>
                 <p>LG Washing Machine with AI Direct Drive</p>
                 </div></a>
              </div>

             </div>
          </div>

          </div></div>
        </OwlCarousel>
        </div>
        </div>
      )

}
