import React from 'react';
import { useParams } from 'react-router-dom';


export default function Detail() {
  const param= useParams();
  //const id= param;
  console.warn(param.id);
  return(
    <>
  <div dangerouslySetInnerHTML={{__html: '<strong>strong text</strong>'}} />
    </>
  )
}
