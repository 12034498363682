import React from 'react'
import { Link } from 'react-router-dom'

export default function Homecontact() {

  return (
    <>
      <section className="contact-sec">
      <canvas id="space"></canvas>
         <div className='row'>
             <div className='title-globe'>
<h2>Making every brand's dream </h2>
<p>a reality all over the world</p>

             </div>

         </div>
        <div className='row globelnet'><div className="col-md-12 position-relative"> <div className="world-map">
      <div className="pin-wrap">
        <img src="assets/images/map_world.png" className="world-map-img" alt=""/>

        <div className="pin wow bounceInDown" data-wow-delay="0.6s"  id="newyork" >
          <div className="office-card">
            <h4><font ><font >New York</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >1-201-627-1333</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >HSAD America, Inc. 111 Sylvan Ave. 2nd Floor South Building, Englewood Cliffs, NJ 07632</font></font></td>
              </tr>
              <tr>
                <td className="item-name web"><font ><font >web</font></font></td>
                <td><Link to="https://www.hsadusa.com"><font ><font >www.hsadusa.com</font></font></Link></td>
              </tr>
            </tbody></table>
          </div>
        </div>
        <div className="pin wow bounceInDown" data-wow-delay="0.9s" id="la">
          <div className="office-card">
            <h4><font ><font >L.A.</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >1-213-251-8822</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >3550 Wilshire Blvd. Suite 1200 Los Angeles, CA, 90010</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="1s" id="bangkok">
          <div className="office-card">
            <h4><font ><font >Bangkok</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >66-63-271-5772</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >75/66 Richmond Bldg., 18th floor Sukhumvit 26 Klongtan Klongtoey Bangkok 10110 Thailand</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="1.2s" id="jakarta">
          <div className="office-card">
            <h4><font ><font >Jakarta</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >62-812-8032-4374</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Gandaria Office 8 20th Floor unit A5, JL Sultan Iskandar Muda, Kebayoran Lama, Jakarta Selantan 12240 Indonesia</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="1.4s" id="manila">
          <div className="office-card">
            <h4><font ><font >Manila</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >63-916-763-2838</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >15 Francisco Legaspi St. Maybunga, Pasig City, Philippines</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="1.6s" id="taipei">
          <div className="office-card">
            <h4><font ><font >Taipei</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >886-90-829-4288</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >7F, No.47, Lane3, Jihu Road, Neihu District, Taipei 114, Taiwan ROC</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>
        <div className="pin wow bounceInDown" data-wow-delay="8s" id="kor">
          <div className="office-card">
            <h4><font ><font >HQ Seoul</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >02-705-2600, 2700</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >LG Mapo Building, 155, Mapo-daero, Mapo-gu, Seoul, Republic of Korea</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>



        <div className="pin wow bounceInDown" data-wow-delay="7.8s" id="hanoi">
          <div className="office-card">
            <h4><font ><font >Hanoi</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >84-90-229-2100</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >2503, Keangnam Landmark 72, Me Tri, Tu Liem, Hanoi</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="2s" id="newdelhi">
          <div className="office-card">
            <h4><font ><font >India</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >+91-120-4624900</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >KP Towers, 12th Floor, Tower D, Plot No. C-001B, Sector-16B
Noida-201301, Uttar Pradesh</font></font></td>
              </tr>
              <tr>
                <td className="item-name web"><font ><font >Website</font></font></td>
                <td><Link to="https://www.hsad.co.in" ><font ><font >www.hsad.co.in</font></font></Link></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="2.2s" id="sydney">
          <div className="office-card">
            <h4><font ><font >Sydney</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >61-2-8805-4000</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >2 Wonderland Drive, Eastern Creek, NSW 2766, Australia</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="2.4s" id="singapore">
          <div className="office-card">
            <h4><font ><font >Singapore</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >65-6333-6092</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >8 Temasek Blvd, #27-01, Singapore 038988</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="2.6s" id="amsterdam">
          <div className="office-card">
            <h4><font ><font >Amsterdam</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >31-62-943-9811</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Krijgsman 1, 1186DM, Amstelveen, The Netherlands</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="2.8s" id="stockholm">
          <div className="office-card">
            <h4><font ><font >Stockholm</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >46-721-693-324</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Esbogatan 18, 164 74 Kista</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="3s" id="frank">
          <div className="office-card">
            <h4><font ><font >Frankfurt</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >inquiry</font></font></td>
                <td><a href="mailto:contact@hsadgermany.com"><font ><font >contact@hsadgermany.com</font></font></a></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Alfred-Herrhausen-Allee 3-5 65760 Eschborn</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="3.2s" id="paris">
          <div className="office-card">
            <h4><font ><font >Fly</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >33-1-49-89-45-27</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >117 Avenue des Nations, 93420 Villpinte, France,</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="3.4s"  id="warszawa">
          <div className="office-card">
            <h4><font ><font >Warsaw</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >48-224-817-215</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Mokotow Nova, Woloska 22, 02-675 Warsaw, Poland</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="3.6s"  id="london">
          <div className="office-card">
            <h4><font ><font >London</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >44-737-733-5937</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Velocity 2, Brooklands Drive, Brooklands, Weybridge, KT13OSL</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="3.8s"  id="milano">
          <div className="office-card">
            <h4><font ><font >Milan</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >39-331-142-5968</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Via Aldo Rossi 4, 20149 Milano (MI) Italy</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="4s"  id="budapest">
          <div className="office-card">
            <h4><font ><font >Budapest</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >36-30-352-2970</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >1097 Budapest, Konyves Kalman Krt. 3/a</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="4.2s"  id="moscow">
          <div className="office-card">
            <h4><font ><font >Moscow</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >7-495-641-5890</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >6th Floor, 4, 4th Lesnoy per., Moscow, 125047, Russia, GIIR RUS, LLC</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="4.4s" id="praha">
          <div className="office-card">
            <h4><font ><font >Prague</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >420-606-304-945</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >LG Electronics Polska SP.Zo.o., Czech Branch Ceskomoravska 2420/15 190 93 Prague 9, Czech Republic</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin  wow bounceInDown" data-wow-delay="4.6s" id="jeddah">
          <div className="office-card">
            <h4><font ><font >Jeddah</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >966-50-897-1202</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >702A, Jameel Square, Prince Mohammed Bin Abdulaziz St, Al Andalus, Jeddah 23326 , Kingdom of Saudi Arabia</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin  wow bounceInDown" data-wow-delay="4.8s" id="amman">
          <div className="office-card">
            <h4><font ><font >Amman</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >962-770-657-907</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Abdali Project, Rafik al Hariri Avenue, The Boulevard, The Central Square, 5th Floor, Amman, Jordan</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="5s" id="dubai">
          <div className="office-card">
            <h4><font ><font >Dubai</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >971-(0)4-554-7513</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Al Shatha Tower 24th Media city, Dubai, UAE</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="5.2s" id="istanbu">
          <div className="office-card">
            <h4><font ><font >Istanbul</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >90-532-525-5359</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Kaptanpasa Mah, Piyalepasa Bulvan No.:73 Ortadogu Plaza, Kat7 Okmeydani. Sisli. Istanbul Turkiye</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="5.4s" id="tel">
          <div className="office-card">
            <h4><font ><font >Tel Aviv</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >972-9-954-9241</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >4th floor, Azrieli Business Park Building E, Medihat ha Yehudim 89 st, Herzliya, Israe</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="5.6s" id="erbil">
          <div className="office-card">
            <h4><font ><font >Erbil</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >964-751-829-7557</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Empire business complex Buliding No. C5 1st floor LG office, Erbil, Iraq</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="5.8s" id="cairo">
          <div className="office-card">
            <h4><font ><font >Cairo</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >20-1-09922-2079</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Exhibition Area at Ring Read Fifth Settlement, Beside Bavarian Auto, Cairo, Egypt</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="6s" id="johan">
          <div className="office-card">
            <h4><font ><font >Johannesburg</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >27-11-323-8041</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Growthpoint Business Park 10A &amp;, 10B Tonetti St, Halfway House, Midrand, 1685, South Africa</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="6.2s" id="nairobi">
          <div className="office-card">
            <h4><font ><font >Nairobi</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >254-20-2327363/65</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >14 Riverside Off Riverside Drive, Hanover Block,3rd Floor, Nairobi, Kenya</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="6.4s" id="panama">
          <div className="office-card">
            <h4><font ><font >Panama City</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >507 6595 6973</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >LG Business Center, Calle Aquilino de la Guardia con 48 Este, Marbella,Panama, Republica de Panama</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="6.6s" id="bogota">
          <div className="office-card">
            <h4><font ><font >Bogotá</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >57-313-794-5752</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Piso 10, Carrera 11 #94A-34, Bogota, Colombia</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="6.8s" id="lima">
          <div className="office-card">
            <h4><font ><font >Lima</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >51-945-100-730</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >Av. Republica de Colombia 791-Piso 12 San Isidro, Lima, Peru</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="7s" id="santiago">
          <div className="office-card">
            <h4><font ><font >Santiago</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >56-975-755-498</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >LG ELECTRONICS INC. CHILE LIMITADA AV. ISIDORA GOYENECHEA 2800, PISO 10 LAS CONDES, SANTIAGO CHILE</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="7.2s" id="saopaulo">
          <div className="office-card">
            <h4><font ><font >Sao Paulo</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >55-11-99819-3039</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >HSAd, Av. Dr. Chucri Zaidan 940, 3 andar, Vila Cordeiro, Sao Paulo, SP, Brazil</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="7.4s" id="buenos">
          <div className="office-card">
            <h4><font ><font >Buenos Aires</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >54-11-5352-5454</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >LG Electronic Argentina SA Carlos Maria Della Paolera 265, Piso 26, Buenos Aires, Argentina</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="7.6s" id="guatemala">
          <div className="office-card">
            <h4><font ><font >Guatemala City</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >502-4163-1265</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >11 CALLE 19-86 EDIFICIO ADAMANT APTO 413 ZONA 15, VISTA HERMOSA 3, 01001, GUATEMALA, GUATEMALA</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="pin wow bounceInDown" data-wow-delay="7.8s"  id="quito">
          <div className="office-card">
            <h4><font ><font >Quito</font></font></h4>
            <table>
              <tbody><tr>
                <td className="item-name phone"><font ><font >phone call</font></font></td>
                <td><font ><font >593-99-731-1791</font></font></td>
              </tr>
              <tr>
                <td className="item-name adress"><font ><font >address</font></font></td>
                <td><font ><font >C. 16 NO - Dr. Francisco Martínez Aguirre 41, Guayaquil 090904, Ecuador</font></font></td>
              </tr>
            </tbody></table>
          </div>
        </div>

      </div>
    </div></div></div>
     
	   {/* <div className="address wow bounceInLeft" ><img src="assets/images/loc.png" alt=""/></div> */}
	 
	</section>
</>
  )
};

