import React, {useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { slice } from "lodash";
import { Helmet } from 'react-helmet';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function SubmitResume() {
  const ServicesRef = useRef(null);


// console.warn(name);

  const gotoServices = () =>
    window.scrollTo({
      top: ServicesRef.current.offsetTop,
      behavior: "smooth",
      // You can also assign value "auto"
      // to the behavior parameter.
    });


  
  // const FILE_SIZE = 160 * 102477;
    // const SUPPORTED_FORMATS = [
    //   "image/jpg",
    //   "image/jpeg",
    //   "image/gif",
    //   "image/png"
    // ];


  const formik = useFormik({
    initialValues: {
      
      name: '',
      email: '',
      mobile: '',
      exp: '',
      ctc: '',
      agree: false,
   
      file_name: '',
    },
    validationSchema:Yup.object({
      name:Yup.string()
      .matches(/^[a-zA-Z ]*$/, "Must be only Alphabets")
      .required("This is a required field"),

      mobile:Yup.string()
      .required("This is an required field")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, "Must be exactly 10 digits")
        .max(10, "Must be exactly 10 digits"),

      email:Yup.string()
      .required("This is an required field")
      .email("Please provide a valid email"),

      exp:Yup.string()
      .required("This is an required field"),

      // ctc:Yup.string()
      // .required("This is an required field"),

      file_name: Yup
        .mixed()
        .required("A file is required"),
        // .test(
        //   "fileSize",
        //   "File too large",
        //   value => value && value.size <= FILE_SIZE
        // ),
        // .test(
        //   "fileFormat",
        //   "Unsupported Format",
        //   value => value && SUPPORTED_FORMATS.includes(value.type)
        // ),

      agree:Yup.bool() // use bool instead of boolean
      .oneOf([true], "You must accept the terms and conditions"),
  
    }),
    onSubmit: (value,onSubmitProps) => {
      // console.log('all values : ',value);
      // console.log('onSubmitProps : ',onSubmitProps);
      // e.preventDefault();
      // console.warn(name, email, mobile, ctc, exp, coverLetter);
      // let data = { name, email, mobile, ctc, exp, coverLetter }
      // console.log(name);
      let formData = new FormData();
      formData.append('name', value.name);
      formData.append('email', value.email);
      formData.append('mobile', value.mobile);
      // formData.append('ctc', value.ctc);
      formData.append('exp', value.exp);
      formData.append('agree', value.agree);
      formData.append('id', value.id);
      formData.append('job_title', value.job_title);
      formData.append('file_name', value.file_name);
  
      let result =  fetch('https://hsadindia.co.in/admin/api-submit-resume', {
        method: 'POST',
  
  
        body: formData,
        // headers: {
        //   // 'Content-type': 'application/json; charset=UTF-8',
        //   'Content-type': 'multipart/form-data; ',
        // },
      })
        .then((response) => response.json())
        .then((data) => {
          if(data.status ==='1'){
            Swal.fire({
              //position: 'top-end',
              icon: 'success',
              title: data.msg,
              showConfirmButton: false,
              timer: 1500
            })


            

             onSubmitProps.setSubmitting(false);
             onSubmitProps.resetForm();
             document.getElementById("flexCheckChecked").checked = false;
             document.getElementById("resume").value = "";
            

          }else{
            Swal.fire({
              //position: 'top-end',
              icon: 'error',
              title: data.data,
              showConfirmButton: false,
              timer: 1500
            })
          }
         
  
          // setPosts((posts) => [data, ...posts]);
          // setTitle('');
          // setBody('');
        })
        .catch((err) => {
          console.log(err.message);
        });

      // console.log("form submitted",e)

    }
  })

//  async function submitResume(e) {
//   e.preventDefault();
//     // console.warn(name, email, mobile, ctc, exp, coverLetter);
//     // let data = { name, email, mobile, ctc, exp, coverLetter }
//     // console.log(name);
//     let formData = new FormData();
//     formData.append('name', name);
//     formData.append('email', email);
//     formData.append('mobile', mobile);
//     formData.append('ctc', ctc);
//     formData.append('exp', exp);
//     formData.append('file_name', coverLetter);


//     let result = await fetch('https://hsadindia.co.in/admin/api-submit-resume', {
//       method: 'POST',


//       body: formData,
//       // headers: {
//       //   // 'Content-type': 'application/json; charset=UTF-8',
//       //   'Content-type': 'multipart/form-data; ',
//       // },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if(data.status =='1'){
//           Swal.fire({
//             //position: 'top-end',
//             icon: 'success',
//             title: data.msg,
//             showConfirmButton: false,
//             timer: 1500
//           })
//         }else{
//           Swal.fire({
//             //position: 'top-end',
//             icon: 'error',
//             title: data.data,
//             showConfirmButton: false,
//             timer: 1500
//           })
//         }
       

//         // setPosts((posts) => [data, ...posts]);
//         // setTitle('');
//         // setBody('');
//       })
//       .catch((err) => {
//         console.log(err.message);
//       });


//       // alert("Data has been saved");
    

//   }

// console.log(formik.values)
// console.log(formik.touched)


const [apilink] = useState("https://hsadindia.co.in/admin/api-all-jobs");
    // const [apilink, setApilink] = useState([])
    const [post, setPost] = useState([]);
    // const [isCompleted, setIsCompleted] = useState(false)
    const params = useParams();
const {name} = params;
console.log(params);
    const [index] = useState();
    let initialPosts = slice(post,(name-1) , name);
  
    fetch(apilink)
      .then((res) => res.json())
      .then((json) => setPost(json.data))
      .catch((e) => console.log(e));
  return (
    <>
    <Helmet>
    <title>Best Creative Agency Delhi, Brand Advertising Agency Delhi | HSAD India | Submit Resume</title>
    <meta name="description"content="HS Ad best Advertising, Creative agency in Delhi India provides integrated marketing services to build a brand with Digital Media, ATL / BTL and PR mediums around the Globe."/>
    <meta name="keywords" content="" /> 
    </Helmet>
      {/* <div className="section aboutme" >
        <i id="moveDown" className="fa fa-chevron-down fa-3x bounce" onClick={gotoServices}></i>
       
          <div className="banner-con">
            <h1 style={{ color: "white" }} className="animated fadeInDown">Submit Resume</h1>
            <p className="animated fadeInDown">Upload CV</p>
          </div>

        <div className="submit-banner-image">
          <img src="../assets/images/banner-submit-resume.jpg" className='img-d' alt="" />
          <img src="../assets/images/banner-submit-resume.jpg" className='img-m' alt="" />
        </div>
      </div> */}
      <section className="contactBox-sec">
        <div className="container">
          <div className="row justify-content-center">
          {initialPosts.map((itemm, i) => {
            return (
              <>
                {(() => {
                  return (
                    <>
            <div className="col-md-7">
            
              <div className="post-list">
                
          
                <div key={itemm.id}>
                <h2 className="post-title">{itemm.JobTitle}</h2>
                <p className="loction"><strong>Job Location: </strong> {itemm.JobLocation}</p>
                <p><strong>Description</strong></p>
                <div dangerouslySetInnerHTML={{__html: itemm.Description}} />
                <p><strong>Requirements:</strong></p>
                <div dangerouslySetInnerHTML={{__html: itemm.Requirements}} />
              
                <p><strong>Roles & Responsibilities:</strong></p>
                <div dangerouslySetInnerHTML={{__html: itemm.RolesResponsibilities}} />
                
                <p><strong>Job Location:</strong>   {itemm.JobLocation}</p>
                <p><strong>Job Category:</strong>   {itemm.JobCategory}</p>
                <p><strong>Job Type:</strong>   {itemm.JobType}</p>
                <p><strong>Job Vacancies:</strong>  {itemm.JobVacancies}</p>
                </div>
             
            </div>
            </div>




            <div className="col-md-5">
              <div className="apply-form">
                <h3>Apply for this position</h3>
                <form onSubmit={ formik.handleSubmit }>
                  <div className=" form-group"> 
                  <input type='hidden'  name='job_title' onChange={formik.handleChange} onBlur={formik.handleBlur} value={itemm.JobTitle}/>
                  <input type='hidden'  name='id' onChange={formik.handleChange} onBlur={formik.handleBlur} value={itemm.id}/>
                    <input type='text' className="form-control" name='name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} placeholder="Name"/>
                    {formik.touched.name && formik.errors.name && <div className="form-alert"><i className='icon icon-alert'></i> {formik.errors.name} </div>}
                  </div>
                  <div className="form-group">
                    <input type='text' className="form-control"  name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} placeholder="Email"/>
                    {formik.touched.email && formik.errors.email && <div className="form-alert"><i className='icon icon-alert'></i> {formik.errors.email} </div>}
                  </div>
                  <div className="form-group">
                    <input type='text' className="form-control"  name='mobile' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobile} placeholder="Mobile"/>
                    {formik.touched.mobile && formik.errors.mobile && <div className="form-alert"><i className='icon icon-alert'></i> {formik.errors.mobile} </div>}
                  </div>
                  <div className="form-group">
                    <input type='text' className="form-control"  name='exp' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.exp} placeholder="Exp"/>
                    {formik.touched.exp && formik.errors.exp && <div className="form-alert"><i className='icon icon-alert'></i> {formik.errors.exp} </div>}
                  </div>
                  {/* <div className="form-group">
                    <input type='file' className="form-control"  name='file_name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.file_name} placeholder="exp"/><br></br>
                    {formik.touched.exp && formik.errors.file_name && <p style={{color:"red"}}>{formik.errors.file_name} </p>}
                  </div> */}

                  <div className="form-group">
                    <input id='resume' type='file' className="form-control"  name='file_name' onChange={(event)=>{
                      let reader = new FileReader();
                      reader.onload=()=>{
                        if(reader.readyState ===2){
                          // console.log(reader.result);
                           formik.setFieldValue("file_name",event.target.files[0]);
                          // formik.setPreview(reader.result)
                        }
                      }
                      reader.readAsDataURL(event.target.files[0])
                    }} onBlur={formik.handleBlur} />
                    {formik.touched.exp && formik.errors.file_name && <div className="form-alert"><i className='icon icon-alert'></i> {formik.errors.file_name} </div>}
                    <div className='note-upload'>Upload PDF or Word file only</div>
                  </div>
                  
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox"  onChange={formik.handleChange} onBlur={formik.handleBlur} value="true" name="agree" id="flexCheckChecked" />
                    {formik.touched.agree && formik.errors.agree && <div className="form-alert"><i className='icon icon-alert'></i> {formik.errors.agree} </div>}
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                    By using this form you agree with the storage and handling of your data by this website.
                    </label>
                  </div>
                  <div className="form-group">
                    {/* <button onClick={submitResume} className="btn btn-sub-re">Submit</button> */}
                    <button className="btn-sub-re" type='submit'> SUBMIT</button>
                  </div>

                </form>


              </div>

            </div>
            </>
              );
            })()}
          </>
        );
      })}
          </div>

        </div>
      </section>
    </>
  )
}
