import React, { useState } from "react";
import { Link } from "react-router-dom";
import { slice } from "lodash";

export default function Joblisting() {
    const [apilink] = useState("https://hsadindia.co.in/admin/api-all-jobs");
    // const [apilink, setApilink] = useState([])
    const [post, setPost] = useState([]);
    // const [isCompleted, setIsCompleted] = useState(false)
    const [index] = useState();
    let initialPosts = slice(post, 0, index);
  
    fetch(apilink)
      .then((res) => res.json())
      .then((json) => setPost(json.data))
      .catch((e) => console.log(e));
      
    return (
      <>
        <ul className="job-list-body">
          {initialPosts.map((itemm, i) => {
            return (
                <li key={itemm.id}>
                {(() => {
                  return (
                    <>
                     
                        <div className="post-title">{itemm.JobTitle}</div>
                        <div className="post-loction">{itemm.JobLocation}</div>
                        <div className="post-detail"><Link to={`/submit-resume/${itemm.id}`}> Apply </Link></div>
                      
                    </>
                  );
                })()}
              </li>
            );
          })}
        </ul>
      </>
    );
}
