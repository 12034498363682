import React from 'react'
import Bannervideo from './homecomponent/Bannervideo'
import OurteamSec from './homecomponent/OurteamSec'
import PartnersSec from './homecomponent/PartnersSec'
import ProjectSec from './homecomponent/ProjectSec'
import Homecontact from './homecomponent/Homecontact'
// import AwardSec from './homecomponent/AwardSec'


export default function Home() {
  return (
    <>
<div className='main-wrapper'>
 <Bannervideo/>
 <ProjectSec/>
 <div className='divider'></div>
 <PartnersSec/>
<OurteamSec/>
<Homecontact></Homecontact>
{/* <AwardSec/> */}
</div>
    </>
  )
}