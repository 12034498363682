import React, { useState, useEffect } from "react";
import { slice } from "lodash";

export default function OurAmazingTeamTitle() {
    const [apilink] = useState(
        "https://hsadindia.co.in/admin/api-all-home-our-amazing-team"
      );
      const [post, setPost] = useState([]);
     // const [isCompleted, setIsCompleted] = useState(false);
      const [index] = useState();
      let initialPosts = slice(post, 0, index);
      const getData = (apilink) => {
        fetch(apilink)
          .then((res) => res.json())
          .then((json) => setPost(json.data))
          .catch((e) => console.log(e));
      };
      
      useEffect(() => {
        getData(apilink);
      }, [apilink]);
  return (
    <>
         {initialPosts.map((item, i) => {
                  return (
                          <React.Fragment key={item.id}>
                           <div className="row title-b">
                           <h2>Our Amazing Team</h2>
                           {/* <p dangerouslySetInnerHTML={{ __html: item.detail }} />  */}
                           </div>
                          </React.Fragment>
                )
            })} 
    </>
  )
}
