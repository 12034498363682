import React, { useRef } from 'react'
import Whowe from './Whowe';
// import WhoWeAre from './WhoWeAre'

export default function Bannervideo() {
    const ServicesRef = useRef(null);
  
    const gotoServices = () =>
      window.scrollTo({
        top: ServicesRef.current.offsetTop,
        behavior: "smooth",
        // You can also assign value "auto"
        // to the behavior parameter.
      });


  return (
    <>
<div className="section home-banner" data-anchor="aboutme">

<video playsInline autoPlay muted loop className="des_img">
      <source src="assets/images/NEW-FINAL-RENDER-FOR-WEBSITE.mp4" type="video/mp4"/>
</video>
<i className="fa fa-chevron-down fa-3x bounce" onClick={gotoServices}></i>
</div>


<section  className="whowe-sec-n" ref={ServicesRef}>
	  
<Whowe/>
        
         
</section> 
    </>
  )
}
