import React, { useRef } from 'react'
import { Link } from 'react-router-dom';

export default function Blogs() {
    const ServicesRef = useRef(null);
  
    const gotoServices = () =>
      window.scrollTo({
        top: ServicesRef.current.offsetTop,
        behavior: "smooth",
        // You can also assign value "auto"
        // to the behavior parameter.
      });
  return (
    <>
       <div className="section aboutme" data-anchor="aboutme">
	    <div className="banner-con ">
      <h1 style={{color:"white"}} className="animated fadeInDown">Our Latest Blog</h1>
      <p className="animated fadeInDown">Post</p>
   
    </div>
    <i className="fa fa-chevron-down fa-3x bounce" onClick={gotoServices}></i>

	  <img src="assets/images/banner-blog-d.jpg"   className='img-d' alt=""/>
      <img src="assets/images/banner-team-m.jpg"   className='img-m' alt=""/>
     </div>
     <section className="blog-sec" ref={ServicesRef}>
              <div className="container">
                <div className="row">
                     <div className="col-md-6">
                         <div className="blog-box">
                                <div className="img-box">
                                  <img src="assets/images/blogs/Blog-1.jpg"  alt=""/>
                                   <div className='b-detalis'>
                                       <div className='name-date'>
                                           <div className='post-by'>Sanchi Kapoor</div>
                                           <div className='post-date'>09 March 2023</div>

                                       </div>
                                       <div className='post-cat'>
                                           <div className='p-catogry'>Design</div>
                                       </div>
                                   </div>
                                  </div>
                                  <div className='bloglist-content'>
                                    <h2 className='title'><Link to="/">Sed eiusmod tempor incididunt</Link></h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</p>
                                    <Link to="/" className='btn-post-link'>Read post</Link>
                                  </div>
                          
                          </div> 
                     </div>
                </div>



              </div>



     </section>
    </>
  )
}
