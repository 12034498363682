import React, { useState, useEffect } from "react";
import { slice } from "lodash";
export default function CareerwhychoosusTitle() {
    const [apilink] = useState(
        "https://hsadindia.co.in/admin/api-all-careers"
      );
      const [post, setPost] = useState([]);
     // const [isCompleted, setIsCompleted] = useState(false);
      const [index] = useState();
      let initialPosts = slice(post, 0, index);
      const getData = (apilink) => {
        fetch(apilink)
          .then((res) => res.json())
          .then((json) => setPost(json.data))
          .catch((e) => console.log(e));
      };
      
      useEffect(() => {
        getData(apilink);
      }, [apilink]);
  return (
    <>
       <div className="title-h">
          {initialPosts.map((itemm, i) => {
            return (
              <React.Fragment key={itemm.id}>
                  <h2>Be a Part of <span>HSAD Culture</span></h2>
                  <p dangerouslySetInnerHTML={{ __html: itemm.why_choose_us }}></p>
              </React.Fragment>
            )
          })}
        </div>
    </>
  )
}
