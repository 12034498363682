import React from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
export default function EnqueryForm() {
    const formik = useFormik({
        initialValues: {
          name: "",
          email: "",
          mobile: "",
          sub: "",
          msg: "",
        },
        validationSchema: Yup.object({
          name: Yup.string()
            .matches(/^[a-zA-Z ]*$/, "Must be only Alphabets")
            .required("This is a required field"),
          mobile: Yup.string()
            .required("This is an required field")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, "Must be exactly 10 digits")
            .max(10, "Must be exactly 10 digits"),
          email: Yup.string()
            .required("This is an required field")
            .email("Please provide a valid email"),
          sub: Yup.string().required("This is an required field"),
          msg: Yup.string().required("This is an required field"),
        }),
        onSubmit: (value, onSubmitProps) => {
          console.log("all values : ", value);
          console.log("onSubmitProps : ", onSubmitProps);
          let formData = new FormData();
          formData.append("name", value.name);
          formData.append("email", value.email);
          formData.append("mobile", value.mobile);
          formData.append("sub", value.sub);
          formData.append("msg", value.msg);
    
          let result = fetch("https://hsadindia.co.in/admin/api-send-enquiry", {
            method: "POST",
    
            body: formData,
            // headers: {
            //   // 'Content-type': 'application/json; charset=UTF-8',
            //   'Content-type': 'multipart/form-data; ',
            // },
          })
            .then((response) => response.json())
            
            .then((data) => {
              if (data.status === "1") {
                Swal.fire({
                  //position: 'top-end',
                  icon: "success",
                  title: data.msg,
                  showConfirmButton: false,
                  timer: 1500,
                });
    
                onSubmitProps.setSubmitting(false);
                onSubmitProps.resetForm();
                "textarea".text("");
                // document.getElementById("flexCheckChecked").checked = false;
                // document.getElementById("resume").value = "";
              } else {
                Swal.fire({
                  //position: 'top-end',
                  icon: "error",
                  title: data.data,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
    
              // setPosts((posts) => [data, ...posts]);
              // setTitle('');
              // setBody('');
            })
            .catch((err) => {
              console.log(err.message);
            });
    
          // console.log("form submitted",e)
        },
      });
  return (
    <>
      
                  <div className="contact-form">
                    <div id="form-messages"></div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className='row'>
                        <div className='col-md-6'>
                      <div className="row">
                        <div className="form-group col-md-12 has-feedback " >
                          <input 
                          type="text" 
                          className="form-control"
                            name="name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            placeholder="Name*"
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className="form-alert">
                              <i className="icon icon-alert"></i>
                              {formik.errors.name}{" "}
                            </div>
                          )}

                          <span
                            className="glyphicon form-control-feedback"
                            aria-hidden="true"
                          ></span>
                          <div className="help-block with-errors"></div>
                        </div>

                        <div className="form-group col-md-12 has-feedback">
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            placeholder="Email*"
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="form-alert">
                              <i className="icon icon-alert"></i>
                              {formik.errors.email}{" "}
                            </div>
                          )}

                          <span
                            className="glyphicon form-control-feedback"
                            aria-hidden="true"
                          ></span>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-12 has-feedback ">
                          <input
                            type="text"
                            className="form-control"
                            name="mobile"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile}
                            placeholder="Mobile*"
                          />
                          {formik.touched.mobile && formik.errors.mobile && (
                            <div className="form-alert">
                              <i className="icon icon-alert"></i>
                              {formik.errors.mobile}{" "}
                            </div>
                          )}
                        </div>
                        {/* <div className="form-group col-md-12 has-feedback ">
                          <input
                            type="text"
                            className="form-control"
                            name="sub"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.sub}
                            placeholder="Subject*"
                          />
                          {formik.touched.sub && formik.errors.sub && (
                            <div className="form-alert">
                              <i className="icon icon-alert"></i>
                              {formik.errors.sub}{" "}
                            </div>
                          )}
                        </div> */}
                      </div>
                      </div>
                      <div className='col-md-6'>
                      <div className="row">
                        <div
                          className="form-group  col-md-12 has-feedback"
                          data-wow-delay="1s"
                        >
                          <textarea
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-control"
                            id="message"
                            name="msg"
                            placeholder="Message*"
                            value={formik.values.msg}
                          />

                          {/* <input type='text' className="form-control" name='message' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} placeholder="name" /><br></br> */}
                          {formik.touched.msg && formik.errors.msg && (
                            <div className="form-alert">
                              <i className="icon icon-alert"></i>
                              {formik.errors.msg}{" "}
                            </div>
                          )}
                        </div></div>
                      </div>
                      </div>
                      <div className='row'><div
                        className="form-foot d-flex justify-content-between">
                        <button className="btn-sub-re" type="submit">
                          {" "}
                          Send
                        </button></div>
                      </div>
                    </form>
                  </div>
                
    </>
  )
}
