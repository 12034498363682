// import React, { useRef } from 'react'
import React, {useRef} from 'react'

// import WorkDep from './workcomponent/WorkDep';
import { Helmet } from 'react-helmet';
import HomeTabContant from './homecomponent/HomeTabContant';

export default function OurWork() {
    // const ServicesRef = useRef(null);
  
    // const gotoServices = () =>
    //   window.scrollTo({
    //     top: ServicesRef.current.offsetTop,
    //     behavior: "smooth",
    //     // You can also assign value "auto"
    //     // to the behavior parameter.
    //   });

  return (
    <>
    <Helmet>
    <title>Best Creative Agency Delhi, Brand Advertising Agency Delhi | HSAD India | OurWork</title>
    <meta name="description"content="HS Ad best Advertising, Creative agency in Delhi India provides integrated marketing services to build a brand with Digital Media, ATL / BTL and PR mediums around the Globe."/>
    <meta name="keywords" content="" /> 
    </Helmet>
     {/* <div className="section aboutme" data-anchor="aboutme">
	    <div className="banner-con ">
      <h1 style={{color:"white"}} className="animated fadeInDown"></h1>
      <p className="animated fadeInDown">Portfolio</p>
    </div>
    <i className="fa fa-chevron-down fa-3x bounce" onClick={gotoServices}></i>

	  <img src="assets/images/banner-our-work.jpg"  className='img-d' alt=""/>
      <img src="assets/images/banner-our-work-m.jpg"  className='img-m'  alt=""/>
  </div> */}
  
<div className='clearfix'></div>
  {/* <WorkDep></WorkDep> */}
  <div className="section  project-sec outwork-work" >
  <div className="container">
         <div className="title-h mb-5">
         
                  <h2  className='text-white'>Our <span>Work</span></h2>
                  {/* <p dangerouslySetInnerHTML={{ __html: itemm.why_choose_us }}></p> */}
          
        </div>

         </div>
  <div className="container">	  
	<div className="row">
	   <div className="col-md-12">
  <HomeTabContant></HomeTabContant>
  </div></div></div>
</div>
    </>
  )
}
