import React, { useState, useRef, useEffect } from "react";
import { slice } from "lodash";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
export default function Retail() {
  const ServicesRef = useRef(null);

  const gotoServices = () =>
    window.scrollTo({
      top: ServicesRef.current.offsetTop,
      behavior: "smooth",
      // You can also assign value "auto"
      // to the behavior parameter.
    });

  //const [apilink, setApilink] = useState('json-digital/our-works1.json')
  const [apilink, setApilink] = useState([]);

  const [post, setPost] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [index, setIndex] = useState(3);
  let initialPosts = slice(post, 0, index);
  const getData = (apilink) => {
    fetch(apilink)
      .then((res) => res.json())
      .then((json) => setPost(json.data))
      .catch((e) => console.log(e));
  };
  const loadMore = () => {
    setIndex(index + 3);
    console.log(index);
    console.log(post.length);
    if (index >= post.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };

  // const newdata = (apil) => {
  //   setApilink(apil)
  // }

  function getdeta(name) {
    // alert(name);

    setPost([]);
    setIsCompleted(false);
    setIndex(3);
    initialPosts = slice(post, 0, index);

    setApilink(name);
    getData(apilink);
  }

  useEffect(() => {
    getData(apilink);
  }, [apilink]);

  return (
    <>
    <Helmet>
    <title>Best Creative Agency Delhi, Brand Advertising Agency Delhi | HS Ad India | Retail</title>
    <meta name="description"content="HS Ad best Advertising, Creative agency in Delhi India provides integrated marketing services to build a brand with Digital Media, ATL / BTL and PR mediums around the Globe."/>
    <meta name="keywords" content="" /> 
    </Helmet>
      <div className="section aboutme" data-anchor="aboutme">
        <div className="banner-con ">
          <h1 style={{ color: "white" }} className="animated fadeInDown">
            Retail
          </h1>
          <p className="animated fadeInDown">what we design</p>
        </div>
        <i
          className="fa fa-chevron-down fa-3x bounce"
          onClick={gotoServices}
        ></i>

        <img src="assets/images/banner-our-work.jpg" className="img-d" alt="" />
        <img
          src="assets/images/banner-our-work-m.jpg"
          className="img-m"
          alt=""
        />
      </div>

      <section className="pro-listing-sec" ref={ServicesRef}>
        <ul className="pro-list-box">
          <li>
            <button
              onClick={() =>
                getdeta("https://hsadindia.co.in/admin/api-all-works/LG/Retail")
              }
              className="clint-box"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <div className="image-box">
                <img src="assets/images/project/lg-retail.jpg" alt="" />
              </div>
              <div className="clint-detail">
                <div className="d-box">
                  <img src="assets/images/clint/lglogo.png" alt="" />
                </div>
              </div>
            </button>
          </li>
        </ul>
      </section>

      <div
        className="modal fade project-pop"
        id="staticBackdrop"
        aria-hidden="true"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="icon icon-close close-pop"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {" "}
            </button>

            <div className="modal-body">
              <ul className="work-list">
                {initialPosts.map((item, i) => {
                  return (
                    <>
                      {(() => {
                        if (item.type === "youtube") {
                          return (
                            <>
                              <li className="list-box" key={item.id}>
                                <a
                                  href={`${item.file}`}
                                  data-fancybox="video-gallery"
                                >
                                  <div className={`${item.className}`}>
                                    <img src={`${item.file}`} alt="" />
                                  </div>
                                  <div className="cn-foot">
                                    {/* <h2>{item.title}</h2> */}
                                    <p>{item.body}</p>
                                  </div>
                                </a>
                              </li>
                            </>
                          );
                        }
                        if (item.type === "video") {
                          return (
                            <>
                              <li className="list-box loadbox" key={item.id}>
                                <a
                                  href={`${item.file}`}
                                  data-fancybox="video-gallery"
                                >
                                  <div className={`${item.className}`}>
                                    <video playsInline className="des_img">
                                      {" "}
                                      <source
                                        src={`${item.file}`}
                                        type="video/mp4"
                                        draggable="true"
                                      />
                                    </video>
                                  </div>
                                  <div className="cn-foot">
                                    {/* <h2>{item.title}</h2> */}
                                    <p>{item.body}</p>
                                  </div>
                                </a>
                              </li>
                            </>
                          );
                        }
                      })()}
                    </>
                  );
                })}
              </ul>
              <ul className="work-list">
                {initialPosts.map((item, i) => {
                  return (
                    <>
                      {(() => {
                        if (item.type === "img") {
                          return (
                            <>
                              <li className="list-box loadbox" key={item.id}>
                                <a
                                  href={`${item.file}`}
                                  data-fancybox="video-gallery"
                                >
                                  <div className={`${item.className}`}>
                                    <img src={`${item.file}`} alt="" />
                                  </div>
                                  <div className="cn-foot">
                                    {/* <h2>{item.title}</h2> */}
                                    <p dangerouslySetInnerHTML={{ __html: item.body }} />
                                  </div>
                                </a>
                              </li>
                            </>
                          );
                        }
                      })()}
                    </>
                  );
                })}
              </ul>
              <div className="load-more-box d-flex justify-center">
                {isCompleted ? (
                  <button
                    onClick={loadMore}
                    type="button"
                    className="disabled"
                    id="loadMore"
                  >
                    That's It
                  </button>
                ) : (
                  <button onClick={loadMore} type="button" id="loadMore">
                    Load More
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
