import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MainMenu from "./MainMenu";
export default function Header() {
  const [toggle, setToggle] = useState(false);
  const [aclass, setAclass] = useState("");
  const [vclass, setvisibleAclass] = useState("");
  const handleClick = () => {
    setToggle(!toggle);
    if (vclass === "visible") {
      setvisibleAclass("");
    } else {
      setvisibleAclass("");
    }
    //setvisibleAclass('');
  };
  const handleClickkk = () => {
    setToggle(!toggle);
    if (toggle) {
      setAclass("");
    } else {
      setAclass("active");
    }
  };
  const handleCliclogo = () => {
    setAclass("");
    setToggle("");
  };
  const handleClickk = () => {
    setToggle(!toggle);
    if (toggle) {
      setAclass("");
    } else {
      setAclass("active");
    }
    // if(vclass ==='visible'){
    //   setvisibleAclass('');
    // }else{
    //   setvisibleAclass('visible');
    // }
  };
  const location = useLocation();
  // alert(location.pathname.replace('/',''));
  //let cururl = location.pathname.replace('/','');
  return (
    <>
      {toggle ? (<MainMenu name="visible" handleclass={handleClickkk} />) : ( // <MainMenu name={location.pathname ==="/"?"visible":""}/>
        <></>
      )}
      <div className="header-main">
          <div className={`nav-header  ${ location.pathname === "/" ? "head-h" : "head-in" }`}>
          <div className={`nav-brand-w ${ location.pathname === "/" ? "nologo" : "yeslogo" }`}onClick={() => { handleCliclogo();}}>
            <Link to="/"><img src="assets/images/logo_logotype.svg" alt="" /></Link>
          </div>
          <div className="hamburger-guide" id="hamburger">
            {/* <a href="#conNect" className="talk" id="talkcon" >Let's Talk</a>  */}
            <button className={`hamburger-init  ${aclass}`} onClick={() => {handleClick(); handleClickk();}} >
              <span className="bar top-bar"></span>
              <span className="bar middle-bar"></span>
              <span className="bar bottom-bar"></span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
