import React from "react";
import { Link } from "react-router-dom";
import EnqueryForm from "./EnqueryForm";
//import Swal from "sweetalert2";
export default function Footer() {
  // const [subEmail, setSubEmail] = useState("");
  // async function doSubscribe(e) {
  //   e.preventDefault();
  //   let formData = new FormData();
  //   formData.append("subEmail", subEmail);
  //   let result = await fetch("https://hsadindia.co.in/admin/api-do-subscribe", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.status === "1") {
  //         Swal.fire({
  //           //position: 'top-end',
  //           icon: "success",
  //           title: data.msg,
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //       } else {
  //         Swal.fire({
  //           //position: 'top-end',
  //           icon: "error",
  //           title: data.data,
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //       }

  //       // setPosts((posts) => [data, ...posts]);
  //       // setTitle('');
  //       // setBody('');
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });

  //   // alert("Data has been saved");
  // }


  return (
    <>
      <div className="section fp-auto-height footer-sec">
        <div className="footer">
          <div className="container">
            <div className="row ">
              <div className="col-md-4" >
                {/* <div className="foot-logo"><img src="assets/images/logo_foot.svg" alt="" /></div> */}
              <div className="socialm-link wow fadeInRight" data-wow-delay="0.9s">
                    <h3>LET'S GET SOCIAL</h3>
                    {/* <p>IT'LL MAKE YOUR DAY.</p> */}
                    <div className="social-link">
                      <a href="https://www.facebook.com/IndiaHSAD">
                        <i className="icon icon-fb"></i>
                      </a>
                      {/* <Link to="/">
                        <i className="icon icon-tw"></i>
                      </Link> */}
					  <a href="https://www.instagram.com/hsad_india/">
                        <i className="icon icon-insta"></i>
                      </a>
                      <a href="https://www.linkedin.com/company/3877705/admin/feed/posts/">
                        <i className="icon icon-linkedin"></i>
                      </a>
                      <a href="https://www.youtube.com/@hsad_india">
                        <i className="icon icon-youtube"></i>
                      </a>
                    </div>
                  </div>
              </div>
              <div className="col-md-3" >
              <div
                    className="foot-nav wow fadeInRight"
                    data-wow-delay="0.6s"
                  >
                    <h3>SITE MAP</h3>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/our-work">Work</Link>
                      </li>
                      <li>
                        <Link to="/team">Team</Link>
                      </li>
                      <li>
                        <Link to="/careers">Careers</Link>
                      </li>
                      {/*<li><Link to='/'>Advantages</Link></li>
				       <li><Link to='/'>Partners</Link></li>
				      <li><Link to='/'>Features</Link></li>
					   <li><Link to='/'>Benefits</Link></li> */}

                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact</Link>
                      </li>
                    </ul>
                  </div>
              </div>
              <div className="col-md-5">
              <div className="enq-form wow fadeInUp">
		  <h3>WRITE TO US</h3>
		  <EnqueryForm></EnqueryForm>
		</div>
                {/* <div className="footer-links">
                  <div
                    className="newsletter wow fadeInRight"
                    data-wow-delay="0.3s"
                  >
                    <h3>
                      Our <span>Newsletter</span>
                    </h3>
                  
                    <form>
                      <div className="input-group d-box">
                       
                        <input
                          onChange={(e) => setSubEmail(e.target.value)}
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          pattern="[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+"
                          placeholder="Enter your email address"
                        />

                        <button
                          onClick={doSubscribe}
                          className="btn newsletter-btn"
                          type="button"
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                 

               
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="copywrite">
          Copyright &copy; 2024. All rights reserved. HSAD India.
        </div>
      </div>
    </>
  );
}
