import React, { useState, useEffect } from "react";
import { slice } from "lodash"
import { Link } from "react-router-dom";

export default function ContactUsMain() {
    const [apilink] = useState(
        "https://hsadindia.co.in/admin/api-all-contact-us-main"
      );
      const [post, setPost] = useState([]);
     // const [isCompleted, setIsCompleted] = useState(false);
      const [index] = useState();
      let initialPosts = slice(post, 0, index);
      const getData = (apilink) => {
        fetch(apilink)
          .then((res) => res.json())
          .then((json) => setPost(json.data))
          .catch((e) => console.log(e));
      };
      
      useEffect(() => {
        getData(apilink);
      }, [apilink]);
  return (
    <>

     <section className="contactBox-sec" >
     <div className="container">
<div className="contant-boxb">
{initialPosts.map((item, i) => {
                  return (
          <React.Fragment key={item.id}>
     <div className="image-b wow fadeInLeft" data-wow-delay="0.3s">
      <img src={`https://hsadindia.co.in/admin/uploads/contactus/${item.image}`} alt="" />
     </div>
     <div className="contant-text wow fadeInRight" data-wow-delay="0.3s">
        <h2>Contact <span>Us</span></h2>
        <p dangerouslySetInnerHTML={{ __html: item.detail }}></p>
       <ul>
        <li><i className="icon icon-map-locator"></i> <span dangerouslySetInnerHTML={{ __html: item.address}}></span></li>
         <li><i className="icon icon-phone"></i> <Link to={`tel:${item.mobile}`}><span dangerouslySetInnerHTML={{ __html: item.mobile}}></span></Link></li>
         <li><i className="icon icon-envelope"></i><Link to={`mailto:${item.email}`}><span dangerouslySetInnerHTML={{ __html: item.email}}></span></Link></li>
       </ul>
     </div>
     </React.Fragment>
        )
    })}
</div>

    </div>


     </section> 
    </>
  )
}
