import React from 'react'
// import Homeworktab from './Homeworktab';
import HomeTabContant from './HomeTabContant';
// import WhatWeDo from './WhatWeDo';

export default function ProjectSec() {
  return (
    <>
      <div className="section  project-sec " data-anchor="projects">
	  
	  {/* <div className="container">
	      <div className="row title-b">
			  <WhatWeDo/>
		  </div>
	  </div> */}
<div className="container">	  
	<div className="row">
	   <div className="col-md-12">
{/* <Homeworktab/> */}
<HomeTabContant/>
		</div>
	
	</div>    

	</div>

	  
 
  </div>


  
    </>
  )
}
