import React, { useState, useEffect } from "react";
import { slice } from 'lodash';
import { Helmet } from "react-helmet";

export default function Team() {
  // const ServicesRef = useRef(null);
  // const gotoServices = () =>
  //   window.scrollTo({
  //     top: ServicesRef.current.offsetTop,
  //     behavior: "smooth",
  //     // You can also assign value "auto"
  //     // to the behavior parameter.
  //   });
  //const [apilink] = useState('https://hsadindia.co.in/admin/api-all-team')
const [apilink] = useState('json-team/team-hsad.json')

  const [post, setPost] = useState([])
  // const [setIsCompleted] = useState(false)
  const [index] = useState()
  let initialPosts = slice(post, 0, index)
  //let initialPost = {};
  const getData = (apilink) => {
    fetch(apilink)
      .then((res) => res.json())
      //.then((json) => setPost(json.data))
      .then((json) => setPost(json))
      .catch((e) => console.log(e))
  }



  useEffect(() => {
    getData(apilink);
  }, [apilink])

  return (
    <>
    <Helmet>
    <title>Best Creative Agency Delhi, Brand Advertising Agency Delhi | HS Ad India | Team</title>
    <meta name="description"content="HS Ad best Advertising, Creative agency in Delhi India provides integrated marketing services to build a brand with Digital Media, ATL / BTL and PR mediums around the Globe."/>
    <meta name="keywords" content="" /> 
    </Helmet>
      {/* <div className="section aboutme" data-anchor="aboutme">
        <div className="banner-con ">
          <h1 style={{ color: "white" }} className="animated fadeInDown">Our Amazing Team</h1>
          <p className="animated fadeInDown">People</p>

        </div>
        <i className="fa fa-chevron-down fa-3x bounce" onClick={gotoServices}></i>

         <img src="assets/images/banner-team-d.jpg" className='img-d' alt="" />
        <img src="assets/images/banner-team-m.jpg" className='img-m' alt="" />
      </div> */}
      <section className="careerContainer-sec" >
         <div className="container">
         <div className="title-h mb-5">
         
                  <h2>Our Amazing <span>Team</span></h2>
                  {/* <p dangerouslySetInnerHTML={{ __html: itemm.why_choose_us }}></p> */}
          
        </div>

         </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">


              <ul className="myTeam">
                {
                  initialPosts.map((itemm, i) => {
                    return (
                      <li className="pro-link" key={itemm.id}>
                        {(() => {
                          return (
                            <>
                              <button  data-bs-toggle="modal" data-bs-target={`#staticBackdrop${itemm.id}`}>
                                {/* <div className="image-box"><img src={`${itemm.file}`} alt="" /></div> */}
                                <div className="image-box"><img src={`assets/images/team/${itemm.file}`} alt="" /></div>
                                <div className="pro-foot">
                                  <h3 dangerouslySetInnerHTML={{ __html: itemm.userName }}></h3>
                                  <p dangerouslySetInnerHTML={{ __html: itemm.designation }}></p>
                                </div>
                              </button>

                              <div className="modal fade propop" id={`staticBackdrop${itemm.id}`} aria-hidden="true" tabIndex="-1">
                                <div className="modal-dialog modal-xl modal-dialog-centered">
                                  <div className="modal-content">
                                    <button type="button" className="btn close-box" data-bs-dismiss="modal" aria-label="Close"><i className='icon icon-close'></i></button>
                                    <div className="modal-body">
                                      <div className="profile-cont animated fadeInUp" key={itemm.id}>

                                        {/* <div className="pro-image-box"><img src={`${itemm.file}`} alt="" /></div> */}
                                        <div className="pro-image-box"><img src={`assets/images/team/${itemm.file}`} alt="" /></div>
                                        <div className="pro-contant">
                                          <h3 dangerouslySetInnerHTML={{ __html: itemm.userName }}></h3>
                                          <h5 dangerouslySetInnerHTML={{ __html: itemm.designation }}></h5>
                                          <p dangerouslySetInnerHTML={{ __html: itemm.Description }}></p>
                                        </div>

                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>

                            </>
                          );
                        })()}
                      </li>
                    );
                  })}
              </ul>


            </div>
          </div></div>

      </section>







    </>
  )
}
