import React, { useState, useEffect } from "react";
import { slice } from "lodash";

export default function CareerwhychoseusList() {
    const [apilink] = useState(
        "https://hsadindia.co.in/admin/api-all-careers"
      );
      const [post, setPost] = useState([]);
     // const [isCompleted, setIsCompleted] = useState(false);
      const [index] = useState();
      let initialPosts = slice(post, 0, index);
      const getData = (apilink) => {
        fetch(apilink)
          .then((res) => res.json())
          .then((json) => setPost(json.data))
          .catch((e) => console.log(e));
      };
      
      useEffect(() => {
        getData(apilink);
      }, [apilink]);
  return (
    <>
    	{initialPosts.map((item, i) => {
                  return (
          <React.Fragment key={item.id}>
        <div className="col-md-6">
      <div className="careerlist">
        <div className="icon-box"><i className={`icon ${item.icon_first}`}></i></div>
        <div className="content-bv" dangerouslySetInnerHTML={{ __html: item.icon_first_details }}></div>
      </div>
     </div>
     <div className="col-md-6">
      <div className="careerlist">
        <div className="icon-box"><i className={`icon ${item.icon_second}`}></i></div>
        <div className="content-bv" dangerouslySetInnerHTML={{ __html: item.icon_second_details }}></div>
      </div>
     </div>
     <div className="col-md-6">
      <div className="careerlist">
        <div className="icon-box"><i className={`icon ${item.icon_third}`}></i></div>
        <div className="content-bv" dangerouslySetInnerHTML={{ __html: item.icon_third_details }}></div>
      </div>
     </div>
     <div className="col-md-6">
     <div className="careerlist">
       <div className="icon-box"><i className={`icon ${item.icon_fourth}`}></i></div>
       <div className="content-bv" dangerouslySetInnerHTML={{ __html: item.icon_fourth_details }}></div>
     </div>
    </div>
     </React.Fragment>
        )
    })}
    </>
  )
}
