import React, {useState} from 'react'
import { NavLink } from "react-router-dom";
export default function MainMenu(props) {
    const [togglev, setToggle] = useState(props.name);
    const handleClick = () => {
          setToggle(togglev==="visible"?'':'visible'); 
          };
  return (
    <>
<div className={`menu-wrapper ${togglev}`}>
    <ul className="menu">
      <li onClick={ () => {handleClick();props.handleclass()}}><NavLink  to="/our-work" className="link-b">Work<br/><span>Our Portfolio</span></NavLink></li>
      <li onClick={ () => {handleClick();props.handleclass()}}><NavLink  to="/team" className="link-b">Team<br/><span>Leadership</span></NavLink></li>
      {/* <li onClick={ () => {handleClick();props.handleclass()}}><NavLink  to="/Blogs" className="link-b">Blogs<br/><span>Post</span></NavLink></li> */}
      <li onClick={ () => {handleClick();props.handleclass()}}><NavLink  to="/careers" className="link-b">Careers<br/><span>Join Our Team</span></NavLink></li>
      <li onClick={ () => {handleClick();props.handleclass()}}><NavLink  to="/contact-us" className="link-b">Contact<br/><span>Let's Talk</span></NavLink></li>
      {/* <li className="menu-item-has-children"><a href="#">Services</a>
        <ul className="menu">
          <li><a href="#">Lorem ipsum dolor sit amet.</a></li>
          <li><a href="#">Lorem ipsum dolor sit amet.</a></li>
          <li><a href="#">Lorem ipsum dolor sit amet.</a></li>
        </ul>
      </li> */}
    </ul>
  </div>
    </>
  )
}
