import React from 'react'

export default function Whowe() {
  return (
    <>
    
      <div className="title-box">

        <h2>Who<br/><span> we are</span></h2>
      </div>
      <div className='who-we-content'>
        <div className='intro-box'>
          <div className='intro-logo'><img src="assets/images/logo_intro.svg" alt=""/></div>
          <p>We visualized the initiative to go beyond the boundaries of traditional advertising agencies to create higher value of existence, and the firm determination to grow and leap with our customers with red diagonal lines of alphabet A and bold font.</p></div>
          <div className='intro-box-down'>
          <div className='intro-title'><img src="assets/images/text-intro.png" alt=""/></div>
          <p>We pursue an extraordinary level with our storytelling power that penetrates the true value of the client’s brand and creative methods that differentiate us from competitors.</p></div>

      </div>

    
    </>
  )
}
