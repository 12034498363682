import React, { useState, useEffect } from "react";
import { slice } from 'lodash';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function HomeTeam() {
    // const [apilink] = useState('https://hsadindia.co.in/admin/api-all-team')
    const [apilink, setApilink] = useState('json-team/team-hsad.json')
    const [post, setPost] = useState([])
    // const [setIsCompleted] = useState(false)
    const [index] = useState()
    let initialPosts = slice(post, 0, index)
    const getData = (apilink) => {
      fetch(apilink)
        .then((res) => res.json())
        //.then((json) => setPost(json.data))
        .then((json) => setPost(json))
        .catch((e) => console.log(e))
    }
  
  
  
    useEffect(() => {
      getData(apilink);
    }, [apilink])

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
       

      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      }
    };
  return (
    <> 
     <Carousel className="profile-slider"   itemClass="carousel-item-padding-40-px" responsive={responsive} draggable={true}showDots={true} autoPlay={true} arrows={false} infinite={true} >

     {
      initialPosts.map((itemm, i) => {
                    return (
                        <>    
                        
                  <div className="item" key={itemm.id}>
                  {(() => {
                          return (
                            <>
           <div className="team-b">
              <div className="img-box">
              
               <div className="avtar"><img src={`assets/images/team/${itemm.file}`} alt=""/></div>
             </div>
            <div className="content-box">
              <div className="d-box">
              <h2 className="name" dangerouslySetInnerHTML={{ __html: itemm.userName }}></h2>
                <div className="designnation" dangerouslySetInnerHTML={{ __html: itemm.designation }}></div>
              </div>
             <div className="red-content" dangerouslySetInnerHTML={{ __html: itemm.Description }}></div>
              <button data-bs-target={`#staticBackdrop${itemm.id}`} data-bs-toggle="modal" className="link-tab">Read More</button>
             </div>
           </div>
           
        
                     </>
                          );
                        })()}
         </div>


                 </>
                 
                 );
                 })}
     </Carousel>
    {/* <div id="team" className="owl-carousel owl-theme ">
      {
      initialPosts.map((itemm, i) => {
                    return (
                        <>    
                        
                  <div className="item" key={itemm.id}>
                  {(() => {
                          return (
                            <>
           <div className="team-b">
              <div className="img-box">
              
               <div className="avtar"><img src={`assets/images/team/${itemm.file}`} alt=""/></div>
             </div>
            <div className="content-box">
              <div className="d-box">
              <h2 className="name" dangerouslySetInnerHTML={{ __html: itemm.userName }}></h2>
                <div className="designnation" dangerouslySetInnerHTML={{ __html: itemm.designation }}></div>
              </div>
             <div className="red-content" dangerouslySetInnerHTML={{ __html: itemm.Description }}></div>
              <button data-bs-target={`#staticBackdrop${itemm.id}`} data-bs-toggle="modal" className="link-tab">Read More</button>
             </div>
           </div>
           
        
                     </>
                          );
                        })()}
         </div>


                 </>
                 
                 );
                 })}


                   
</div>  */}




{
      initialPosts.map((itemm, i) => {
                    return (
                        <> 
                       
                       <div className="modal fade propop" id={`staticBackdrop${itemm.id}`} aria-hidden="true" tabIndex="-1">
                                <div className="modal-dialog modal-xl modal-dialog-centered">
                                  <div className="modal-content">
                                    <button type="button" className="btn close-box" data-bs-dismiss="modal" aria-label="Close"><i className='icon icon-close'></i></button>
                                    <div className="modal-body">
                                      <div className="profile-cont animated fadeInUp" key={itemm.id}>

                                        {/* <div className="pro-image-box"><img src={`${itemm.file}`} alt="" /></div> */}
                                        <div className="pro-image-box"><img src={`assets/images/team/${itemm.file}`} alt="" /></div>
                                      
                                        <div className="pro-contant">
                                          <h3 dangerouslySetInnerHTML={{ __html: itemm.userName }}></h3>
                                          <h5 dangerouslySetInnerHTML={{ __html: itemm.designation }}></h5>
                                          <p dangerouslySetInnerHTML={{ __html: itemm.Description }}></p>
                                        </div>

                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div> 

</>
                 
                 );
                 })}                        
    </>

  )
}